import Swiper, { Navigation, Pagination } from 'swiper';
import inView from 'in-view';

// Menu Trigger
const menuTrigger = document.querySelector('.js-menu-trigger');
if (menuTrigger) {
  const showMenu = () => {
    const mainHeaderNav = document.querySelector('.main-header-nav');
    mainHeaderNav.classList.toggle('active');
    menuTrigger.classList.toggle('active');
  };

  menuTrigger.addEventListener('click', (e) => {
    e.preventDefault();
    showMenu();
  });
}

// Target Section
inView.threshold(0.25);
inView('.target-section').on('enter', (el) => {
  const sectionID = el.id;
  const headerMenus = document.querySelectorAll('.main-header-nav a');
  const targetMenu = document.querySelector(
    `.main-header-nav a[href="#${sectionID}"]`
  );

  [...headerMenus].forEach((item) => {
    item.classList.remove('active');
  });

  targetMenu.classList.add('active');
});

// Case Study
const caseStudySlider = document.querySelector('.case-study-slider');
if (caseStudySlider) {
  const slider = caseStudySlider.querySelector('.swiper');
  const pagination = caseStudySlider.querySelector('.swiper-pagination');
  new Swiper(slider, {
    modules: [Pagination],
    pagination: {
      el: pagination,
      type: 'bullets',
      clickable: true
    }
  });
}

const featuresSlider = document.querySelector('.features-slider');
if (featuresSlider) {
  const slider = featuresSlider.querySelector('.swiper');
  const prevButton = featuresSlider.querySelector('.swiper-prev-button');
  const nextButton = featuresSlider.querySelector('.swiper-next-button');
  const pagination = featuresSlider.querySelector('.swiper-pagination');

  new Swiper(slider, {
    modules: [Navigation, Pagination],
    navigation: {
      prevEl: prevButton,
      nextEl: nextButton
    },
    pagination: {
      el: pagination,
      type: 'bullets',
      clickable: true
    }
  });
}

// Seminar Popup
const popupButtons = document.querySelectorAll('.js-seminar-popup');
if (popupButtons) {
  const seminarPopup = (popupID = null) => {
    if (popupID === null) return;

    const activePopup = document.querySelector(popupID);
    const closeButton = activePopup.querySelector('.js-close-popup');
    const container = document.querySelector('.seminar-popup__container');

    // Add Active Class
    activePopup.classList.add('active');

    // Check Click Outside
    activePopup.addEventListener('click', () => {
      activePopup.classList.remove('active');
    });

    // Close Button
    closeButton.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      activePopup.classList.remove('active');
    });

    // Check Click Inside
    container.addEventListener('click', (e) => {
      e.stopPropagation();
    });
  };

  popupButtons.forEach((button) => {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      const popupID = button.getAttribute('href');
      seminarPopup(popupID);
    });
  });
}

// FAQs
const questions = document.querySelectorAll('.question');
if (questions) {
  const showAnswer = (target = null) => {
    if (target === null) return;
    target.classList.toggle('active');
  };

  questions.forEach((question) => {
    question.addEventListener('click', () => {
      const target = question.closest('.faq-item');
      showAnswer(target);
    });
  });
}

// Forms
const checkInput = (input = null) => {
  if (input === null) return;

  const validState = input.validity;

  if (
    validState.valueMissing ||
    validState.typeMismatch ||
    validState.patternMismatch ||
    validState.customError
  ) {
    input.setCustomValidity(input.dataset.errorMessage);
  } else {
    input.setCustomValidity('');
  }
  input.reportValidity();
};

const sendingFormData = (formElm = null) => {
  if (formElm === null) return;
  if (false === formElm.checkValidity()) {
    const invalidField = formElm.querySelector(':invalid');
    const validState = invalidField.validity;
    if (
      validState.valueMissing ||
      validState.typeMismatch ||
      validState.patternMismatch
    ) {
      invalidField.setCustomValidity(invalidField.dataset.errorMessage);
    } else {
      invalidField.setCustomValidity('');
    }
    invalidField.reportValidity();
    return;
  }

  const redirectURL = formElm.action;
  const formID = '1FAIpQLSeeC0pz9hLPnuEcoSpxDCUnsEX-wgE9xCZJ5XjrKCXnFoUwFw';
  let postURL = new URL(
    `https://docs.google.com/forms/u/0/d/e/${formID}/formResponse`
  );
  const formData = new FormData(formElm);
  formData.forEach((value, key) => {
    postURL.searchParams.append(key, value);
  });

  fetch(postURL, { method: 'POST' })
    .then(() => {
      window.location.href = redirectURL;
    })
    .catch(() => {
      window.location.href = redirectURL;
    });
};

const forms = document.querySelectorAll('form');
if (forms) {
  forms.forEach((form) => {
    const inputs = form.querySelectorAll('input:required');
    inputs.forEach((input) => {
      input.addEventListener('change', () => {
        checkInput(input);
      });
    });
  });
}

const jsFormSubmitButtons = document.querySelectorAll('.js-form-submit-button');
if (jsFormSubmitButtons) {
  jsFormSubmitButtons.forEach((button) => {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      const parentForm = button.form;
      sendingFormData(parentForm);
    });
  });
}
